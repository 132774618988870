﻿@import "../ui-variables";

.win-tile {
    display: block;
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    text-align: center;
    z-index: 1;
    border: 2px rgba(0,0,0,0) solid;

    &.win-tile-large {
        height: 310px;
        width: 310px;
    }

    &.win-tile-medium {
        height: 150px;
        width: 150px;
    }

    &.win-tile-small {
        height: 70px;
        width: 70px;
    }

    &.win-tile-wide {
        height: 150px;
        width: 310px;
    }

    &:hover {
        border: 2px @hover-color solid;
    }

    &:active, &.active {
        border: 2px @active-color solid;
    }



    a {
        text-decoration: none;
    }


    .win-tile-icon {
        font-size: 4em;
        margin-top: 23px;
        color: white;
    }

    .win-tile-title {
        color: white;
    }



    .win-tile-notification-right {
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 16px;
        color: white;
    }

    .win-tile-notification-left {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 16px;
        color: white;
    }
}
